import { gql } from '@apollo/client';

const SF_COMPANIES = gql`
  query SfCompanies {
    sfCompanies {
      sfdc_company_id
      sfdc_company_name
    }
  }
`;

const UNMATCHED_DEALS_COMPANIES = gql`
  query unmatchedDealsCompanies {
    unmatchedDealsCompanies {
      company_name
      finance_lead_email
    }
  }
`;

const AUMNI_DEALS_BY_COMPANY = gql`
  query AumniDealByCompany($company_name: String!) {
    aumniDealsByCompanyName(company_name: $company_name) {
      series__c
      shares__c
      purchase_cost__c
      original_purchase_cost
      currency_code
      primary_secondary__c
      total_round__c
      pre_money__c
      lead_co_investor__c
      closedate
      company_name
      fund__c
      aumni_id
    }
  }
`;

const SF_DEALS_BY_COMPANY = gql`
  query SfDealsByCompany($company_name: String!) {
    sfDealsByCompany(company_name: $company_name) {
      series__c
      shares__c
      purchase_cost__c
      primary_secondary__c
      total_round__c
      pre_money__c
      lead_co_investor__c
      closedate
      company_name
      fund__c
      sf_id
      merge_blocked
    }
  }
`;

const GC_DEALS = gql`
  query OutputDeals {
    outputDeals {
      company_name
      closedate
      series__c
      shares__c
      purchase_cost__c
      primary_secondary__c
      total_round__c
      pre_money__c
      lead_co_investor__c
      aumni_id
      sf_id
      user_email
      resolve_datetime
    }
  }
`;

const MATCHED_DEALS = gql`
  query MatchedDeals {
    matchedDeals {
      aumni_id
      sf_id
      aumni_composite_id
      sf_composite_id
      company_name
      closedate
      series__c
      shares__c
      purchase_cost__c
      primary_secondary__c
      total_round__c
      pre_money__c
      lead_co_investor__c
      merge_type
      merge_time
      user_email
    }
  }
`;

export const MONTHLY_CASH_BALANCE_FILTER_QUERY_DOCUMENT = gql`
  query MonthlyCashBalanceFilterQuery {
    monthlyCashBalanceFilter {
      accounts {
        ...BalanceAccountAllFields
      }
      firstAvailableRecordDate
      lastAvailableRecordDate
    }
  }
`;

export const MONTHLY_CASH_BALANCE_REPORT_DATA_DOCUMENT = gql`
  query MonthlyCashBalanceReportData(
    $inputData: MonthlyBalancePlanningDataInput!
  ) {
    monthlyCashBalanceReportData(inputData: $inputData) {
      accountName
      accountNumber
      closingBalance
      recordDate
      recordKey
      relatedEntitiesClosingBalance
      relatedEntities {
        accountName
        accountNumber
        closingBalance
        recordKey
      }
    }
  }
`;

export const PENDING_INVESTMENTS_CASH_RECORD_QUERY_DOCUMENT = gql`
  query PendingInvestmentsCashRecord(
    $inputData: PendingInvestmentsQueryInput!
  ) {
    pendingInvestmentsCashRecord(inputData: $inputData) {
      cashBalance {
        ...PendingInvestmentsCashValueAllFields
      }
      creditLine {
        ...PendingInvestmentsCashValueAllFields
      }
      outstandingBalance {
        ...PendingInvestmentsCashValueAllFields
      }
      totalSubscriptions
    }
  }
`;

export const PENDING_INVESTMENTS_ENTITIES_QUERY_DOCUMENT = gql`
  query PendingInvestmentsEntities($inputData: PendingInvestmentsQueryInput!) {
    pendingInvestmentsEntities(inputData: $inputData) {
      amount
      availableCash
      availableLoc
      cashActivity
      date
      entityId
      entityName
      entityType
      fundedFrom
      locActivity
      metadata {
        activitiesNeedAdjustment
        cashIsNegative
        locIsNegative
      }
      sfdcFundId
    }
  }
`;

export const PENDING_INVESTMENTS_FILTER_QUERY_DOCUMENT = gql`
  query PendingInvestmentsFilter {
    pendingInvestmentsFilter {
      sfdcFundId
      sfdcFundName
    }
  }
`;

export const PENDING_INVESTMENTS_PENDING_ENTITIES_QUERY_DOCUMENT = gql`
  query PendingInvestmentsPendingEntities(
    $inputData: PendingInvestmentsChangesInput!
  ) {
    pendingInvestmentsPendingEntities(inputData: $inputData) {
      amount
      availableCash
      availableLoc
      cashActivity
      date
      entityId
      entityName
      entityType
      fundedFrom
      locActivity
      metadata {
        activitiesNeedAdjustment
        cashIsNegative
        locIsNegative
      }
      sfdcFundId
    }
  }
`;

export const MANDATE_TRACKER_DETAILS_QUERY_DOCUMENT = gql`
  query MandateTrackerDetails($inputData: MandateTrackerDetailsInput!) {
    mandateTrackerDetails(inputData: $inputData) {
      allocationTargets {
        allocationPct
        allocationUsdMn
        targetAllocationPct
        targetAllocationUsdMn
      }
      company {
        aggregateCommitment
        companyPipelines
        existingProspectiveLp
        gcFunds
        hasSfdcCompany
        inPendingState
        sfdcCompanyName
        sfdcCompanyUrl
      }
      contact {
        contactEmail
        contactName
        contactPhone
        contactRole
        hasSfdcContact
        preferredMethod
      }
      firmId
      geography {
        geographicPreferences
        isInternational
      }
      strategyPreferences {
        next12MthsInvestedRange {
          ...ValueRangeAllFields
        }
        next12MthsNoFundsRange {
          ...ValueRangeAllFields
        }
        next12MthsPlans
        next12MthsStrategies
        strategyPreferences
      }
      summary {
        consultant
        description
        investmentSummary
        mandateSummary
        providesSpecialSituationsAllocation
      }
    }
  }
`;

export const MANDATE_TRACKER_ASSISTANT_HISTORY_QUERY_DOCUMENT = gql`
  query MandateTrackerAssistantHistory(
    $inputData: MandateTrackerAssistantOverviewInput!
  ) {
    mandateTrackerAssistantHistory(inputData: $inputData) {
      filters {
        ...MandateTrackerAssistantQueryAllFields
      }
      prompts {
        createdAt
        prompt
      }
      sessionId
    }
  }
`;

export const MANDATE_TRACKER_ASSISTANT_OVERVIEW_QUERY_DOCUMENT = gql`
  query MandateTrackerAssistantOverview(
    $inputData: MandateTrackerAssistantOverviewInput!
  ) {
    mandateTrackerAssistantOverview(inputData: $inputData) {
      filters {
        ...MandateTrackerAssistantQueryAllFields
      }
      sessionId
      total
    }
  }
`;

export const MANDATE_TRACKER_FILTER_VALUES_QUERY_DOCUMENT = gql`
  query MandateTrackerFilterValues($inputData: MandateTrackerTypeInput!) {
    mandateTrackerFilterValues(inputData: $inputData) {
      locations {
        ...MandateTrackerLocationAllFields
      }
      strategyPreferences
    }
  }
`;

export const MANDATE_TRACKER_FUNDRAISES_QUERY_DOCUMENT = gql`
  query MandateTrackerFundraises($inputData: MandateTrackerDetailsInput!) {
    mandateTrackerFundraises(inputData: $inputData) {
      expectedCloseDate
      sfdcFundraiseId
      sfdcFundraiseName
    }
  }
`;

export const MANDATE_TRACKER_OVERVIEW_QUERY_DOCUMENT = gql`
  query MandateTrackerOverview(
    $inputData: MandateTrackerOverviewInputPaginatedInput!
  ) {
    mandateTrackerOverview(inputData: $inputData) {
      items {
        aumUsdMn
        firmId
        firmName
        firmType
        investmentUsdMnRange {
          ...ValueRangeAllFields
        }
        isInvestingInNewFunds
        location {
          ...MandateTrackerLocationAllFields
        }
        preqinUrl
        sfdcUrl
      }
      total
    }
  }
`;

export const SWEEP_BALANCE_REPORT_DATA_QUERY_DOCUMENT = gql`
  query SweepBalanceReportData($inputData: SweepBalanceDataInput!) {
    sweepBalanceReportData(inputData: $inputData) {
      accountName
      accountNumber
      closingAvailableBalance
      closingLedgerBalance
      creditAmount
      description
      openingAvailableBalance
      openingLedgerBalance
      transactionDate
      tranTypeDescription
    }
  }
`;

export const SWEEP_BALANCE_FILTER_QUERY_DOCUMENT = gql`
  query SweepBalanceFilter {
    sweepBalanceFilter {
      accounts {
        ...BalanceAccountAllFields
      }
      firstAvailableRecordDate
      lastAvailableRecordDate
    }
  }
`;

const TRANSACTION_LEDGER = gql`
  query TransactionLedger($id: String!) {
    transactionLedger(id: $id) {
      field
      value
    }
  }
`;

const SF_OPPORTUNITIES = gql`
  query SfOpportunities($id: String!) {
    sfOpportunities(id: $id) {
      field
      value
    }
  }
`;

const UNMATCHED_COMPANIES = gql`
  query UnmatchedCompanies($team: Team!) {
    unmatchedCompanies(team: $team) {
      sf_id
      au_id
      finance_lead_email
      company_name
    }
  }
`;

const UNMATCHED_COMPANIES_PAIR = gql`
  query UnmatchedCompaniesPair($team: Team!, $sf_id: String!, $au_id: String!) {
    unmatchedCompaniesPair(team: $team, sf_id: $sf_id, au_id: $au_id) {
      au_company_incorporation_location
      sf_company_incorporation_location
      sf_investment_region_tax
      au_investment_region_tax
      au_website
      sf_website
      au_board_seat_holder
      sf_board_seat_holder
      au_total_liquidation_preference
      sf_total_liquidation_preference
    }
  }
`;

// const UNMATCHED_COMPANIES_PAIR_TAX = gql`
//     query UnmatchedCompaniesPairTax($sf_id: String!, $au_id: String!) {
//         unmatchedCompaniesPairTax(sf_id: $sf_id, au_id: $au_id) {
//             au_company_incorporation_location,
//             sf_company_incorporation_location,
//             sf_investment_region_tax,
//             au_investment_region_tax,
//             au_website,
//             sf_website,
//             au_board_seat_holder,
//             sf_board_seat_holder,
//             au_total_liquidation_preference,
//             sf_total_liquidation_preference
//         }
//     }
// `
//
// const UNMATCHED_COMPANIES_PAIR_FINANCE = gql`
//     query UnmatchedCompaniesPairFinance($sf_id: String!, $au_id: String!) {
//         unmatchedCompaniesPairFinance(sf_id: $sf_id, au_id: $au_id) {
//             au_website,
//             sf_website,
//             au_board_seat_holder,
//             sf_board_seat_holder,
//             au_total_liquidation_preference,
//             sf_total_liquidation_preference
//         }
//     }
// `

const SF_AUMNI_COMPANIES_FILTER_FOR_MATCH = gql`
  query SfAumniCompaniesFilterForMatch($data_source: SfAumniDataSource!) {
    sfAumniCompaniesFilterForMatch(data_source: $data_source) {
      id
      company_name
      website
      finance_lead_email
    }
  }
`;

const SF_AUMNI_COMPANIES_LIST_FOR_MATCH = gql`
  query SfAumniCompaniesListForMatch(
    $company_name: String
    $website: String
    $search_text: String
    $data_source: SfAumniDataSource!
  ) {
    sfAumniCompaniesListForMatch(
      company_name: $company_name
      website: $website
      search_text: $search_text
      data_source: $data_source
    ) {
      id
      company_name
      website
      weight
    }
  }
`;

const SF_NETSUITE_COMPANIES_FILTER_FOR_MATCH = gql`
  query SfNetsuiteCompaniesFilterForMatch($data_source: SfNetsuiteDataSource!) {
    sfNetsuiteCompaniesFilterForMatch(data_source: $data_source) {
      id
      company_name
      website
      finance_lead_email
    }
  }
`;

const SF_NETSUITE_COMPANIES_LIST_FOR_MATCH = gql`
  query SfNetsuiteCompaniesListForMatch(
    $company_name: String
    $search_text: String
    $data_source: SfNetsuiteDataSource!
  ) {
    sfNetsuiteCompaniesListForMatch(
      company_name: $company_name
      search_text: $search_text
      data_source: $data_source
    ) {
      id
      company_name
      weight
    }
  }
`;

const MATCHED_NETSUITE_COMPANIES = gql`
  query MatchedNetsuiteCompanies {
    matchedNetsuiteCompanies {
      sf_company_id
      ns_company_id
      sf_company_name
      ns_company_name
      match_time
      user_email
    }
  }
`;

const MERGED_COMPANIES = gql`
  query MergedCompanies($team: Team!) {
    mergedCompanies(team: $team) {
      au_id
      sf_id
      user_email
      merge_time
      website
      board_seat_holder
      total_liquidation_preference
      company_incorporation_location
      investment_region_tax
      company_name
    }
  }
`;

const MATCHED_COMPANIES = gql`
  query MatchedCompanies {
    matchedCompanies {
      au_id
      sf_id
      user_email
      match_time
      au_company_name
      au_website
      sf_company_name
      sf_website
    }
  }
`;

const SF_ALLVUE_COMPANIES_FILTER_FOR_MATCH = gql`
  query SfAllvueCompaniesFilterForMatch($data_source: SfAllvueDataSource!) {
    sfAllvueCompaniesFilterForMatch(data_source: $data_source) {
      id
      company_name
      finance_lead_email
    }
  }
`;

const SF_ALLVUE_COMPANIES_LIST_FOR_MATCH = gql`
  query SfAllvueCompaniesListForMatch(
    $company_name: String
    $search_text: String
    $data_source: SfAllvueDataSource!
  ) {
    sfAllvueCompaniesListForMatch(
      company_name: $company_name
      search_text: $search_text
      data_source: $data_source
    ) {
      id
      company_name
      weight
    }
  }
`;

const MATCHED_ISSUERS = gql`
  query MatchedIssuers {
    matchedIssuers {
      sf_id
      issuer_code
      user_email
      match_time
      issuer_name
      company_name
    }
  }
`;

const SF_AFFINITY_COMPANIES_FILTER_FOR_MATCH = gql`
  query SfAffinityCompaniesFilterForMatch($data_source: SfAffinityDataSource!) {
    sfAffinityCompaniesFilterForMatch(data_source: $data_source) {
      id
      company_name
      website
      finance_lead_email
    }
  }
`;

const SF_AFFINITY_COMPANIES_LIST_FOR_MATCH = gql`
  query SfAffinityCompaniesListForMatch(
    $company_name: String
    $website: String
    $search_text: String
    $data_source: SfAffinityDataSource!
  ) {
    sfAffinityCompaniesListForMatch(
      company_name: $company_name
      website: $website
      search_text: $search_text
      data_source: $data_source
    ) {
      id
      company_name
      website
      weight
    }
  }
`;

const MATCHED_AFFINITY = gql`
  query MatchedAffinity {
    matchedAffinity {
      af_id
      sf_id
      match_time
      user_email
      sf_company_name
      sf_website
      af_company_name
      af_website
    }
  }
`;

const SF_FUNDS_FOR_NETSUITE_MATCH = gql`
  query sfFundsForNetsuiteMatch {
    sfFundsForNetsuiteMatch {
      sfdc_fund_id
      sfdc_fund_name
    }
  }
`;

const NS_FUNDS_FOR_MATCH = gql`
  query NsFundsForMatch($sf_fund_name: String, $search_text: String) {
    nsFundsForMatch(sf_fund_name: $sf_fund_name, search_text: $search_text) {
      id
      fund_name
      weight
    }
  }
`;

const MATCHED_NETSUITE_FUNDS = gql`
  query MatchedNetsuiteFunds {
    matchedNetsuiteFunds {
      sf_fund_id
      ns_fund_id
      sf_fund_name
      ns_fund_name
      match_time
      user_email
    }
  }
`;

const TAX_TRANSACTIONS = gql`
  query TaxTransactions(
    $fund_names: [String!]
    $years: [Int!]
    $issue_types: [String!]
    $issuers: [TaxTransactionCompanyInput!]
    $schedule_k1_lines: [String]
    $is_legal_fee: Boolean!
  ) {
    taxTransactions(
      fund_names: $fund_names
      years: $years
      issue_types: $issue_types
      issuers: $issuers
      schedule_k1_lines: $schedule_k1_lines
      is_legal_fee: $is_legal_fee
    ) {
      transaction_id
      amount
      m1_adjustment
      taxable_income
      description
      account_number
      document_number
      schedule_k1_line
      company {
        name
        code
      }
      fund {
        name
        code
      }
      data_issues
      posting_year
      posting_date
      company_finance_lead
      notes
      is_public_company
      company_tax_incorporation_location
      reclassed_schedule_k1_mapping
      reclassed_adjustment
      activity
      effective_amount
      fdap_category
    }
  }
`;

const TAX_TRANSACTIONS_FILTER = gql`
  query TaxTransactionsFilter {
    taxTransactionsScheduleK1
    taxTransactionsYears
    taxTransactionsIssueTypes
    taxTransactionsFunds {
      name
      code
    }
    taxTransactionsCompanies {
      name
      code
    }
  }
`;

// TODO: check and remove
const LEGAL_FEES = gql`
  query LegalFees(
    $fund_names: [String!]
    $years: [Int!]
    $issue_types: [String!]
    $issuers: [TaxTransactionCompanyInput!]
  ) {
    taxTransactions(
      fund_names: $fund_names
      years: $years
      issue_types: $issue_types
      issuers: $issuers
      schedule_k1_lines: []
      is_legal_fee: true
    ) {
      transaction_id
      amount
      description
      document_number
      company {
        name
        code
      }
      fund {
        name
        code
      }
      activity
      data_issues
      posting_year
      posting_date
      company_finance_lead
      notes
      was_m1_adjustment_edited
      was_activity_edited
      is_public_company
      company_tax_incorporation_location
    }
  }
`;

const LEGAL_FEES_FILTER = gql`
  query LegalFeesFilter {
    legalFeesYears
    legalFeesIssueTypes
    legalFeesFunds {
      name
      code
    }
    legalFeesCompanies {
      name
      code
    }
  }
`;

const TAX_TRANSACTION_OVERRIDES = gql`
  query TaxTransactionOverrides($transaction_id: String!) {
    taxTransactionOverrides(transaction_id: $transaction_id) {
      transaction_id
      account_number
      document_number
      fund {
        name
        code
      }
      company {
        name
        code
      }
      amount
      m1_adjustment
      taxable_income
      activity
      posting_year
      posting_date
      reviewed_by
      override_status
      created_at
      notes
      description
      is_public_company
      company_tax_incorporation_location
      fdap_category
    }
  }
`;

const TAX_DEAL_REVIEW_PAGE_FILTER = gql`
  query TaxDealReviewPageFilter {
    taxDealReviewPageFilter {
      sfdc_fund_name
      sfdc_company_name
      finance_status
    }
  }
`;

const TAX_LOT_REVIEW_PAGE_DATA = gql`
  fragment taxDeal on TaxDeal {
    deal_name
    qsbs_date
    close_date
    conversion
    total_cost
    sfdc_deal_id
    purchase_cost
    finance_status
    sfdc_fund_name
    conversion_date
    sfdc_company_id
    unrealized_cost
    investment_series
    original_lot_date
    sfdc_company_name
    original_company_id
    original_company_name
    investment_series_general
  }

  query TaxLotReviewPageData(
    $fund_names: [String!]
    $company_names: [String!]
    $finance_statuses: [String!]
  ) {
    taxOriginalDealLotReview(
      fund_names: $fund_names
      company_names: $company_names
      finance_statuses: $finance_statuses
    ) {
      ...taxDeal
    }
    taxDealConversions(
      fund_names: $fund_names
      company_names: $company_names
      finance_statuses: $finance_statuses
    ) {
      ...taxDeal
    }
    taxActiveDealLotSummary(
      fund_names: $fund_names
      company_names: $company_names
      finance_statuses: $finance_statuses
    ) {
      ...taxDeal
    }
  }
`;

const TAX_ORIGINAL_DEAL_LOT_REVIEW = gql`
  query TaxOriginalDealLotReview(
    $fund_names: [String!]
    $company_names: [String!]
    $finance_statuses: [String!]
  ) {
    taxOriginalDealLotReview(
      fund_names: $fund_names
      company_names: $company_names
      finance_statuses: $finance_statuses
    ) {
      deal_name
      qsbs_date
      close_date
      conversion
      total_cost
      sfdc_deal_id
      purchase_cost
      finance_status
      sfdc_fund_name
      conversion_date
      sfdc_company_id
      unrealized_cost
      investment_series
      original_lot_date
      sfdc_company_name
      original_company_id
      original_company_name
      investment_series_general
      is_review_approved
    }
  }
`;

const VALUATION_COMPANIES_LIST = gql`
  query ValuationCompaniesList {
    valuationCompaniesList {
      sfdc_company_id
      sfdc_company_name
      reporting_year
      reporting_quarter
    }
  }
`;

const VALUATION_COMPANY_DETAILS = gql`
  query ValuationCompanyDetails(
    $sfdc_company_id: String!
    $reporting_year: Int!
    $reporting_quarter: Int!
    $financial_statement_type: FinancialStatementType!
  ) {
    valuationCompanyDetails(
      sfdc_company_id: $sfdc_company_id
      reporting_year: $reporting_year
      reporting_quarter: $reporting_quarter
      financial_statement_type: $financial_statement_type
    ) {
      sfdc_company_id
      sfdc_company_name
      reporting_year
      reporting_quarter
      gc_funds
      fund_groups {
        ...FundGroupLegacyAllFields
      }
      lead_name
      fully_diluted_ownership_percent
      cost_basis
      current_valuation
      effective_enterprise_value
      total_liquidation_preference
      initial_investment_date
      description
      closing_date
      lead_investor
      series
      post_money_valuation
      total_capital_raised
      gc_portion_of_raise
      comps_industry
      comps_tev_ebitda
      comps_tev_total_revenue
      comps_total_revenue_1yr_ann_growth
      highlights
      comps_tev_total_revenue_calc
      comps_tev_ebitda_calc

      revenue {
        ...StringValueWithNoteAllFields
      }
      ebitda {
        ...StringValueWithNoteAllFields
      }
      arr {
        ...StringValueWithNoteAllFields
      }
      cash_in_bank {
        ...StringValueWithNoteAllFields
      }
      amount_of_drawn_debt {
        ...StringValueWithNoteAllFields
      }

      cash_in_bank_prev_year
      ebitda_prev_year
      arr_prev_year
      revenue_prev_year
      amount_of_drawn_debt_prev_year

      revenue_next_year
      ebitda_next_year
      arr_next_year
      cash_in_bank_next_year
      amount_of_drawn_debt_next_year

      revenue_growth_rate
      ebitda_growth_rate
      arr_growth_rate

      revenue_growth_rate_next_year
      ebitda_growth_rate_next_year
      arr_next_year_growth_rate
    }
  }
`;

const VALUATION_COMPANY_CONCLUSION = gql`
  query ValuationCompanyConclusion(
    $sfdc_company_id: String!
    $reporting_year: Int!
    $reporting_quarter: Int!
  ) {
    valuationCompanyConclusion(
      sfdc_company_id: $sfdc_company_id
      reporting_year: $reporting_year
      reporting_quarter: $reporting_quarter
    ) {
      reporting_year
      reporting_quarter
      conclusion
    }
  }
`;

const VALUATION_COMPANY_UPDATES = gql`
  query ValuationCompanyUpdates(
    $sfdc_company_id: String!
    $reporting_year: Int!
  ) {
    valuationCompanyUpdates(
      sfdc_company_id: $sfdc_company_id
      reporting_year: $reporting_year
    ) {
      highlights
    }
  }
`;

const NEW_INVESTMENT_ACTIVITIES_LIST = gql`
  query NewInvestmentActivitiesList {
    newInvestmentActivitiesList {
      sfdc_fund_id
      sfdc_fund_name
      reporting_years {
        year
        quarters
      }
      blockers {
        id
        name
        portion
        reporting_years {
          year
          quarters
        }
      }
    }
  }
`;

const NEW_INVESTMENT_ACTIVITIES_DETAILS = gql`
  query NewInvestmentActivitiesDetails(
    $sfdc_fund_id: String!
    $reporting_year: Int!
    $reporting_quarter: Int!
    $blocker_ids: [String!]
  ) {
    newInvestmentActivitiesDetails(
      sfdc_fund_id: $sfdc_fund_id
      reporting_year: $reporting_year
      reporting_quarter: $reporting_quarter
      blocker_ids: $blocker_ids
    ) {
      sfdc_company_id
      sfdc_company_name
      gc_lead
      ownership_percent
      description
      date_of_investment
      pre_money_valuation
      co_investor
      share_type
      amount_invested
      round_size
      lead_investor
      blocker_name
    }
  }
`;

const NETSUITE_FUNDS = gql`
  query {
    netsuiteFunds {
      id
      external_id
      fund_name
    }
  }
`;

const GET_NETSUITE_FUND_DUPLICATES = gql`
  query NetsuiteFundDuplicates($id: String!, $item_name: String!) {
    netsuiteFundDuplicates(target_fund: { id: $id, fund_name: $item_name }) {
      id
      external_id
      fund_name
      weight
    }
  }
`;

const NETSUITE_COMPANIES = gql`
  query {
    netsuiteCompanies {
      id
      external_id
      company_name
    }
  }
`;

const GET_NETSUITE_COMPANY_DUPLICATES = gql`
  query NetsuiteCompanyDuplicates($id: String!, $item_name: String!) {
    netsuiteCompanyDuplicates(
      target_company: { id: $id, company_name: $item_name }
    ) {
      id
      external_id
      company_name
      weight
    }
  }
`;

const FUND_OVERVIEW_FUNDS_LIST = gql`
  query FundOverviewReportFilter {
    fundOverviewReportFilter {
      funds {
        sfdc_fund_id
        sfdc_fund_name
        fund_order
      }
      years_quarters {
        reporting_year
        reporting_quarter
      }
    }
  }
`;

const FUND_OVERVIEW_INVESTMENT_COMPANIES_NAMES = gql`
  query FundOverviewInvestmentCompaniesNames {
    fundOverviewInvestmentCompaniesNames {
      sfdc_company_id
      company_name
      edited_company_name
      is_active
    }
  }
`;

const BLOCKER_MANAGEMENT_BLOCKERS_LIST = gql`
  query BlockerManagementBlockersList {
    blockerManagementBlockersList {
      blockerMainFundId
      blockerMainFundName
      blockerFundId
      blockerFundName
      uniqueBlockerCompanyId
      blockerCompanyName
      ownershipPercent
      isSingleAsset
      isEdited
    }
  }
`;

const IR_PROFILE_COMPANIES = gql`
  query LpProfileFilter {
    lpProfileFilter {
      sfdcCompanyId
      sfdcCompanyName
      closestFutureReportDate
      closestPastReportDate
      allReportDates
    }
  }
`;

export const LP_PROFILE_REPORT_DATA_QUERY_DOCUMENT = gql`
  query LpProfileReportData($inputData: LpProfileDataInput!) {
    lpProfileReportData(inputData: $inputData) {
      sfdcCompanyId
      sfdcCompanyName
      website
      lpProfile
      investorType
      regionGeography
      accountCoverageHolder
      additionalContext
      aggregateCommitment
      advisoryBoardMember
      existingProspectiveLp
      companyGpRelationships
      consultantAdvisorLookup
      mainAddress
      preMeetingNote
      actionItems
      lpContacts {
        ...LpContactAllFields
      }
      fundGroups {
        ...FundGroupAllFields
      }
    }
  }
`;

const COINVEST_COMPANIES = gql`
  query LpCoinvestFilter {
    lpCoinvestFilter {
      sfdc_company_id
      sfdc_company_name
      reporting_year
      reporting_quarter
    }
  }
`;

const LP_COMPANY_DETAILS = gql`
  query LpCoinvestReportData(
    $sfdc_company_id: String!
    $reporting_year: Int!
    $reporting_quarter: Int!
  ) {
    lpCoinvestReportData(
      sfdc_company_id: $sfdc_company_id
      reporting_year: $reporting_year
      reporting_quarter: $reporting_quarter
    ) {
      sfdc_company_name
      series
      gc_funds
      fund_groups {
        ...FundGroupLegacyAllFields
      }
      description
      closing_date
      team_members
      lead_investor
      gc_portion_of_raise
      co_investor_group
      post_money_valuation
      total_capital_raised
      total_unrealized_cost
      total_unrealized_value
      initial_investment_date
      effective_enterprise_value
      total_liquidation_preference
      fully_diluted_ownership_percent
      custom_opportunity
      custom_closing_date
      custom_series
      custom_lead_investor
      custom_gc_portion_of_raise
      custom_coinvest_portion
      custom_coinvest_ownership
      custom_total_capital_raised
      custom_post_money_valuation
      custom_syndicate_investor_group
      custom_total_capital_from_existing_investors
      custom_total_capital_from_new_investors
      custom_limited_partner
      lead
    }
  }
`;

const LP_COINVEST_FUNDS = gql`
  query LpCoinvestFunds(
    $sfdc_company_id: String!
    $reporting_year: Int!
    $reporting_quarter: Int!
  ) {
    lpCoinvestFunds(
      sfdc_company_id: $sfdc_company_id
      reporting_year: $reporting_year
      reporting_quarter: $reporting_quarter
    ) {
      sfdc_fund_id
      sfdc_fund_name
      fund_deployment_to_date
      participation_in_round
      is_active
      is_related
    }
  }
`;

const CARTA_CERTIFICATE_LIST = gql`
  query CartaCertificatesList {
    cartaCertificatesList {
      certificate
      deal_id
      deals_company
      carta_company
      deals_series
      carta_series
      deals_fund
      carta_fund
      close_date
      issued_date
      deals_shares
      carta_shares
      deals_purchase_cost
      carta_cash_paid
    }
  }
`;

const CARTA_CONVERTIBLE_LIST = gql`
  query CartaConvertiblesList {
    cartaConvertiblesList {
      convertible
      deal_id
      deals_company
      carta_company
      deals_series
      deals_fund
      carta_fund
      close_date
      issued_date
      deals_purchase_cost
      carta_principal
      valuation_cap
      valuation_cap_currency
    }
  }
`;

const PORTFOLIO_COMPANIES_LEADS = gql`
  query CompaniesLeads {
    portfolioManagementCompaniesLeads {
      sfdcCompanyId
      sfdcCompanyName
      leadName
      leadId
    }
  }
`;

const PORTFOLIO_TABLE_DATA = gql`
  query CompaniesTable($inputData: PortfolioManagementCompaniesInput!) {
    portfolioManagementCompaniesTable(inputData: $inputData) {
      funds {
        currentTotalValue
        exitMoic
        grossMoic
        investmentDate
        projectedRealizedValue
        sfdcFundId
        sfdcFundName
        totalInvestedCapital
      }
      sfdcCompanyId
      leadName
      leadId
      sfdcCompanyName
      currentTotalValue
      exitMoic
      futureDilution
      grossMoic
      investmentDate
      monthOfExit
      projectedEvAtExit
      projectedRealizedValue
      sfdcLink
      totalInvestedCapital
      effectiveEvAmt
      liquidationPreference
      preferredOwnershipPercent
      affinityLink
    }
  }
`;

const PORTFOLIO_FUNDS = gql`
  query FundsFilter {
    portfolioManagementFundsFilter {
      sfdcFundId
      sfdcFundName
      hasModelled
    }
  }
`;

const PORTFOLIO_FUNDS_SUMMARY = gql`
  query FundsSummary($inputData: PortfolioManagementFundSummaryInput!) {
    portfolioManagementFundsSummary(inputData: $inputData) {
      commited
      deployed
      investable
      remaining
    }
  }
`;

const PORTFOLIO_FUNDS_CHART = gql`
  query FundsChart($inputData: PortfolioManagementFundChartInput!) {
    portfolioManagementFundsChartData(inputData: $inputData) {
      metadata {
        projectedFrom
      }
      points {
        period
        type
        value
        details {
          companyId
          companyName
          value
        }
      }
    }
  }
`;

const PORTFOLIO_FUNDS_TABLE = gql`
  query FundsTable($inputData: PortfolioManagementFundTableInput!) {
    portfolioManagementFundsTable(inputData: $inputData) {
      rows {
        currentTotalValue
        exitMoic
        grossMoic
        sfdcFundId
        sfdcFundName
        totalInvestedCapital
        exitIrr
        exitTvpi
        grossIrr
        grossTvpi
      }
      total {
        currentTotalValue
        exitMoic
        grossMoic
        totalInvestedCapital
        exitIrr
        exitTvpi
        grossIrr
        grossTvpi
      }
    }
  }
`;

const PORTFOLIO_FUNDS_COMPANIES_TABLE = gql`
  query CompaniesTable(
    $inputData: PortfolioManagementFundCompaniesTableInput!
  ) {
    portfolioManagementFundsCompanyTable(inputData: $inputData) {
      rows {
        currentTotalValue
        exitMoic
        grossMoic
        sfdcCompanyId
        sfdcCompanyName
        totalInvestedCapital
        sfdcLink
        exitValue
        isModelled
      }
      total {
        currentTotalValue
        exitMoic
        grossMoic
        totalInvestedCapital
        exitValue
      }
    }
  }
`;

const GET_USER = gql`
  query userData {
    resolveUser {
      email
      id
      exp
      name
      roles
    }
  }
`;

const hrComingSoonEmployees = gql`
  query hrComingSoonEmployees {
    hrComingSoonEmployees {
      segment
      department
      division
      legalEntity
      workEmail
      fullName
      hireDate
      birthdate
      jobTitle
      location
      isIncomplete
      manager
      workPhone
      status
      geid
      adpState {
        status
        url
      }
      bamboohrState {
        status
        url
      }
      netsuiteState {
        status
        url
      }
      employeeNumber {
        value
        canBeChanged
      }
    }
  }
`;

const hrLeavingSoonEmployees = gql`
  query hrLeavingSoonEmployees {
    hrLeavingSoonEmployees {
      department
      division
      legalEntity
      address
      segment
      workEmail
      firstName
      middleName
      lastName
      fullName
      terminationDate
      isIncomplete
      jobTitle
      location
      manager
      workPhone
      status
      geid
      adpState {
        status
        url
      }
      bamboohrState {
        status
        url
      }
      netsuiteState {
        status
        url
      }
      employeeNumber {
        value
        canBeChanged
      }
    }
  }
`;

const hrEmployeesOverview = gql`
  query hrEmployeesOverview {
    hrEmployeesOverview {
      address
      segment
      department
      division
      legalEntity
      firstName
      fullName
      geid
      hireDate
      jobTitle
      lastName
      location
      manager
      isIncomplete
      middleName
      netsuiteState {
        status
        url
      }
      adpState {
        status
        url
      }
      bamboohrState {
        status
        url
      }
      status
      workEmail
      terminationDate
      workPhone
      employeeNumber {
        value
        canBeChanged
      }
    }
  }
`;

const GET_TAX_LOT_REVIEW_FILTERS = gql`
  query filter {
    taxDealLotFilter {
      company {
        sfdcCompanyId
        sfdcCompanyName
        financeStatus
      }
      fund {
        sfdcFundId
        sfdcFundName
      }
      years
    }
  }
`;

const GET_FINANCIAL_STATEMENT_OVERVIEW = gql`
  query conversions($inputData: TaxDealLotInput!) {
    taxDealLotOriginal(inputData: $inputData) {
      closeDate
      conversion
      conversionDate
      dealName
      financeStatus
      investmentSeries
      investmentSeriesGeneral
      isReviewApproved
      lastReviewedBy
      latestOverrideAt
      originalCompanyId
      originalCompanyName
      originalLotDate
      purchaseCost
      qsbsDate
      sfdcCompanyId
      sfdcCompanyName
      sfdcFundId
      sfdcDealId
      sfdcFundName
      totalCost
      unrealizedCost
    }
  }
`;

const taxDealLotConversions = gql`
  query conversions($inputData: TaxDealLotInput!) {
    taxDealLotConversions(inputData: $inputData) {
      closeDate
      conversion
      conversionDate
      dealName
      financeStatus
      investmentSeries
      investmentSeriesGeneral
      isReviewApproved
      lastReviewedBy
      latestOverrideAt
      originalCompanyId
      originalCompanyName
      originalLotDate
      purchaseCost
      qsbsDate
      sfdcCompanyId
      sfdcCompanyName
      sfdcFundId
      sfdcDealId
      sfdcFundName
      totalCost
      unrealizedCost
    }
  }
`;

const GET_DEAL_LOT_SUMMARY = gql`
  query conversions($inputData: TaxDealLotInput!) {
    taxDealLotSummary(inputData: $inputData) {
      closeDate
      conversion
      conversionDate
      dealName
      financeStatus
      investmentSeries
      investmentSeriesGeneral
      isReviewApproved
      lastReviewedBy
      latestOverrideAt
      originalCompanyId
      originalCompanyName
      originalLotDate
      purchaseCost
      qsbsDate
      sfdcCompanyId
      sfdcCompanyName
      sfdcFundId
      sfdcDealId
      sfdcFundName
      totalCost
      unrealizedCost
    }
  }
`;

const matchCompaniesSfAffinityCompaniesFilter = gql`
  query MatchCompaniesSfAffinityCompaniesFilter(
    $inputData: MatchCompaniesSfAffinityFilterInput!
  ) {
    matchCompaniesSfAffinityCompaniesFilter(inputData: $inputData) {
      companyId
      companyName
      financeLeadEmail
      website
      weight
    }
  }
`;

const matchCompaniesSfAllvueCompaniesFilter = gql`
  query MatchCompaniesSfAllvueCompaniesFilter(
    $inputData: MatchCompaniesSfAllvueFilterInput!
  ) {
    matchCompaniesSfAllvueCompaniesFilter(inputData: $inputData) {
      companyId
      companyName
      financeLeadEmail
      website
      weight
    }
  }
`;

const matchCompaniesSfNetsuiteCompaniesFilter = gql`
  query MatchCompaniesSfNetsuiteCompaniesFilter(
    $inputData: MatchCompaniesSfNetsuiteFilterInput!
  ) {
    matchCompaniesSfNetsuiteCompaniesFilter(inputData: $inputData) {
      companyId
      companyName
      financeLeadEmail
      website
      weight
    }
  }
`;

const matchCompaniesSfAumniCompaniesFilter = gql`
  query MatchCompaniesSfAumniCompaniesFilter(
    $inputData: MatchCompaniesSfAumniFilterInput!
  ) {
    matchCompaniesSfAumniCompaniesFilter(inputData: $inputData) {
      companyId
      companyName
      financeLeadEmail
      website
      weight
    }
  }
`;

const matchCompaniesSfAffinityCompanies = gql`
  query MatchCompaniesSfAffinityCompanies(
    $inputData: MatchCompaniesSfAffinityCompaniesInput!
  ) {
    matchCompaniesSfAffinityCompanies(inputData: $inputData) {
      companyId
      companyName
      financeLeadEmail
      website
      weight
    }
  }
`;

const matchCompaniesSfAllvueCompanies = gql`
  query MatchCompaniesSfAllvueCompanies(
    $inputData: MatchCompaniesSfAllvueCompaniesInput!
  ) {
    matchCompaniesSfAllvueCompanies(inputData: $inputData) {
      companyId
      companyName
      financeLeadEmail
      website
      weight
    }
  }
`;

const matchCompaniesSfAumniCompanies = gql`
  query MatchCompaniesSfAumniCompanies(
    $inputData: MatchCompaniesSfAumniCompaniesInput!
  ) {
    matchCompaniesSfAumniCompanies(inputData: $inputData) {
      companyId
      companyName
      financeLeadEmail
      website
      weight
    }
  }
`;

const matchCompaniesSfNetsuiteCompanies = gql`
  query MatchCompaniesSfNetsuiteCompanies(
    $inputData: MatchCompaniesSfNetsuiteCompaniesInput!
  ) {
    matchCompaniesSfNetsuiteCompanies(inputData: $inputData) {
      companyId
      companyName
      financeLeadEmail
      website
      weight
    }
  }
`;

const matchCompaniesSfAffinityMatched = gql`
  query MatchCompaniesSfAffinityMatched {
    matchCompaniesSfAffinityMatched {
      sfCompanyId
      sfCompanyName
      sfWebsite

      afCompanyId
      afCompanyName
      afWebsite

      matchTime
      userEmail
    }
  }
`;

const matchCompaniesSfAllvueMatched = gql`
  query MatchCompaniesSfAllvueMatched {
    matchCompaniesSfAllvueMatched {
      sfCompanyId
      sfCompanyName
      sfWebsite

      avIssuerCode
      avIssuerName
      avWebsite

      matchTime
      userEmail
    }
  }
`;

const matchCompaniesSfAumniMatched = gql`
  query MatchCompaniesSfAumniMatched {
    matchCompaniesSfAumniMatched {
      sfCompanyId
      sfCompanyName
      sfWebsite

      auCompanyId
      auCompanyName
      auWebsite

      matchTime
      userEmail
    }
  }
`;

const matchCompaniesSfNetsuiteMatched = gql`
  query MatchCompaniesSfNetsuiteMatched {
    matchCompaniesSfNetsuiteMatched {
      sfCompanyId
      sfCompanyName
      sfWebsite

      nsCompanyId
      nsCompanyName
      nsWebsite

      matchTime
      userEmail
    }
  }
`;

const matchLegalEntitiesSfAllvueFirmsFilter = gql`
  query matchLegalEntitiesSfAllvueFirmsFilter {
    matchLegalEntitiesSfAllvueFirmsFilter {
      legalEntityId
      legalEntityName
      weight
    }
  }
`;

const matchLegalEntitiesSfAllvueFirmsEntities = gql`
  query matchLegalEntitiesSfAllvueFirmsEntities(
    $inputData: MatchLegalEntitiesEntitiesInput!
  ) {
    matchLegalEntitiesSfAllvueFirmsEntities(inputData: $inputData) {
      legalEntityId
      legalEntityName
      weight
    }
  }
`;

const matchLegalEntitiesSfAllvueFirmsMatched = gql`
  query matchLegalEntitiesSfAllvueFirmsMatched {
    matchLegalEntitiesSfAllvueFirmsMatched {
      avLegalEntityId
      avLegalEntityName
      matchTime
      sfLegalEntityId
      sfLegalEntityName
      userEmail
    }
  }
`;

const financialsDetailed = gql`
  query financialsDetailed(
    $sfdc_company_id: String!
    $reporting_year: Int!
    $reporting_quarter: Int!
  ) {
    valuationCompanyFinancialsDetailed(
      sfdc_company_id: $sfdc_company_id
      reporting_year: $reporting_year
      reporting_quarter: $reporting_quarter
    ) {
      previous_year {
        ...FinancialsYearAllField
      }
      current_year {
        ...FinancialsYearAllField
      }
      next_year {
        ...FinancialsYearAllField
      }
    }
  }
`;

const gqlQueries = {
  SF_COMPANIES,
  UNMATCHED_DEALS_COMPANIES,
  AUMNI_DEALS_BY_COMPANY,
  SF_DEALS_BY_COMPANY,
  GC_DEALS,
  MATCHED_DEALS,
  TRANSACTION_LEDGER,
  SF_OPPORTUNITIES,
  UNMATCHED_COMPANIES,
  UNMATCHED_COMPANIES_PAIR,
  SF_AUMNI_COMPANIES_FILTER_FOR_MATCH,
  SF_AUMNI_COMPANIES_LIST_FOR_MATCH,
  MERGED_COMPANIES,
  MATCHED_COMPANIES,
  SF_ALLVUE_COMPANIES_FILTER_FOR_MATCH,
  SF_ALLVUE_COMPANIES_LIST_FOR_MATCH,
  MATCHED_ISSUERS,
  SF_AFFINITY_COMPANIES_FILTER_FOR_MATCH,
  SF_AFFINITY_COMPANIES_LIST_FOR_MATCH,
  MATCHED_AFFINITY,
  SF_NETSUITE_COMPANIES_FILTER_FOR_MATCH,
  SF_NETSUITE_COMPANIES_LIST_FOR_MATCH,
  MATCHED_NETSUITE_COMPANIES,
  SF_FUNDS_FOR_NETSUITE_MATCH,
  NS_FUNDS_FOR_MATCH,
  MATCHED_NETSUITE_FUNDS,
  LEGAL_FEES,
  LEGAL_FEES_FILTER,
  TAX_TRANSACTIONS,
  TAX_TRANSACTIONS_FILTER,
  TAX_TRANSACTION_OVERRIDES,
  TAX_DEAL_REVIEW_PAGE_FILTER,
  TAX_LOT_REVIEW_PAGE_DATA,
  TAX_ORIGINAL_DEAL_LOT_REVIEW,
  VALUATION_COMPANIES_LIST,
  VALUATION_COMPANY_DETAILS,
  VALUATION_COMPANY_CONCLUSION,
  VALUATION_COMPANY_UPDATES,
  NEW_INVESTMENT_ACTIVITIES_LIST,
  NEW_INVESTMENT_ACTIVITIES_DETAILS,
  NETSUITE_FUNDS,
  GET_NETSUITE_FUND_DUPLICATES,
  NETSUITE_COMPANIES,
  GET_NETSUITE_COMPANY_DUPLICATES,
  FUND_OVERVIEW_FUNDS_LIST,
  FUND_OVERVIEW_INVESTMENT_COMPANIES_NAMES,
  BLOCKER_MANAGEMENT_BLOCKERS_LIST,
  IR_PROFILE_COMPANIES,
  LP_COINVEST_FUNDS,
  COINVEST_COMPANIES,
  LP_COMPANY_DETAILS,
  CARTA_CERTIFICATE_LIST,
  CARTA_CONVERTIBLE_LIST,
  PORTFOLIO_COMPANIES_LEADS,
  PORTFOLIO_TABLE_DATA,
  PORTFOLIO_FUNDS,
  PORTFOLIO_FUNDS_SUMMARY,
  PORTFOLIO_FUNDS_CHART,
  PORTFOLIO_FUNDS_TABLE,
  PORTFOLIO_FUNDS_COMPANIES_TABLE,
  GET_USER,
  hrComingSoonEmployees,
  hrLeavingSoonEmployees,
  hrEmployeesOverview,
  GET_TAX_LOT_REVIEW_FILTERS,
  GET_FINANCIAL_STATEMENT_OVERVIEW,
  taxDealLotConversions,
  GET_DEAL_LOT_SUMMARY,
  matchCompaniesSfAffinityCompaniesFilter,
  matchCompaniesSfAllvueCompaniesFilter,
  matchCompaniesSfNetsuiteCompaniesFilter,
  matchCompaniesSfAumniCompaniesFilter,
  matchCompaniesSfAffinityCompanies,
  matchCompaniesSfAllvueCompanies,
  matchCompaniesSfAumniCompanies,
  matchCompaniesSfNetsuiteCompanies,
  matchCompaniesSfAffinityMatched,
  matchCompaniesSfAllvueMatched,
  matchCompaniesSfAumniMatched,
  matchCompaniesSfNetsuiteMatched,
  matchLegalEntitiesSfAllvueFirmsFilter,
  matchLegalEntitiesSfAllvueFirmsEntities,
  matchLegalEntitiesSfAllvueFirmsMatched,
  financialsDetailed,
};

export default gqlQueries;
